*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background: #0B1315;
}

@font-face {font-family: "Metropolis 1920"; 
  src: url("./components/fonts/Metropolis\ 1920.ttf");
  font-weight: normal;
  font-style: normal;
 }
.slider{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.image{
  width: 1000px;
  height: 640px;
  border-radius: 10px;
}

.right-arrow{
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index:10;
  cursor: pointer;
  user-select: none;
}

.left-arrow{
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index:10;
  cursor: pointer;
  user-select: none; 
}

.slide{
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active{
  opacity: 0;
  transition-duration: 1s;
  transform: scale(1.08);
}




/* .wrapper {
  position: fixed;
  top: 69%;
  left: 88%;
  transform: translate(-50%, -50%);
  z-index: 999;
} */

.toggle_button {
  width: 50px;
  height: 50px;
  /* background: #fff; */
  border-radius: 50px;
  overflow: hidden;
  transition: all 0.2s ease;
  
}
.shake{
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}
.toggle_button:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0)
  }

  1.78571% {
    transform: translate(5px, 0)
  }

  3.57143% {
    transform: translate(0, 0)
  }

  5.35714% {
    transform: translate(5px, 0)
  }

  7.14286% {
    transform: translate(0, 0)
  }

  8.92857% {
    transform: translate(5px, 0)
  }

  10.71429% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

.toggle_button ul li a {
  display: flex;
  align-items: center;
  /* height: 65px;
  padding: 0 35px; */
}

.toggle_button ul li a.share_btn {
  justify-content: center;
  cursor: pointer;
}

.toggle_button ul li a.share_btn .text {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 10px;
  color: #c73a5d;
  margin-right: 15px;
}

.toggle_button ul li a.share_btn .icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  /* border-radius: 50%; */
  /* position: relative; */
  transition: all 1s ease;
}

.toggle_button ul li a .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}


.toggle_button ul li a .text {
  letter-spacing: 2px;
}



.toggle_button.active {
  height: 294px;
}

.toggle_button.active ul li a.share_btn .icon {
  transform: rotate(45deg);
}

/* .toggle_button ul li a.sm {
  visibility: hidden;
}

.toggle_button.active ul li a.sm {
  visibility: visible;
  transition-delay: 0.3s;
} */

.container {
  padding: 0 15px;
}

@media (min-width: 575.98px) {
  .container {
    max-width: 540px;
    margin: auto;
    padding: 0;
  }
}

@media (min-width: 767.98px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 991.98px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1199.98px) {
  .container {
    max-width: 1140px;
  }
}